<template>
  <item-wrapper id="privacy">
    <item-header :title="t('privacy.title')" />
    <main>
      <!-- 西班牙语隐私政策 -->
      <div class="privacy-modal" v-if="locale === 'es_ES'">
        <div class="content">
          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title1.p1") }}</p>
            <span class="font-weight">{{ t("privacy.title1.p2") }}</span>
            <span>{{ t("privacy.title1.p3") }}</span>
          </div>
          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title2.p1") }}</p>
            <div v-for="item in contact" :key="item">
              <span>{{ item.name }}</span>
              <span class="font-weight" v-html="item.description"></span>
            </div>
          </div>

          <div class="content-option" v-for="item in principles" :key="item">
            <p class="font-weight">{{ item.title }}</p>
            <p>{{ item.name }}</p>
            <div v-for="item in item.description" :key="item">
              <ul>
                <li class="font-weight">{{ item.name }}</li>
              </ul>
              <span>{{ item.description }}</span>
            </div>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title6.p1") }}</p>
            <span>{{ t("privacy.title6.p2") }}</span>
            <span class="font-weight">{{ t("privacy.title6.p3") }}</span>
            <span>{{ t("privacy.title6.p4") }}</span>
          </div>

          <div class="content-option" v-for="item in data" :key="item">
            <p class="font-weight">{{ item.title }}</p>
            <p>{{ item.name }}</p>
            <template v-for="item in item.description" :key="item">
              <ul v-if="item.description">
                <li>{{ item.description }}</li>
              </ul>
              <p v-if="item.des" v-html="item.des"></p>
            </template>
          </div>
        </div>
      </div>

      <!-- 法语和英语隐私政策 -->
      <div
        class="privacy-modal"
        v-else-if="locale === 'fr_FR' || 'en_US' || 'en_GB' || 'en_NL' || 'en_IE'"
      >
        <!-- <div class="title">{{ t("privacy.title") }}</div> -->
        <div class="content">
          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title1.p1") }}</p>
            <span
              v-html="t('privacy.title1.p2', { email: 'info@rightphone.com' })"
            ></span>
          </div>
          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title2.p1") }}</p>
            <span v-html="t('privacy.title2.p2')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title3.p1") }}</p>
            <span v-html="t('privacy.title3.p2')"></span>
            <ul>
              <li>{{ t("privacy.title3.list.list1") }}</li>
              <li>{{ t("privacy.title3.list.list2") }}</li>
              <li>{{ t("privacy.title3.list.list3") }}</li>
              <li>{{ t("privacy.title3.list.list4") }}</li>
              <li>{{ t("privacy.title3.list.list5") }}</li>
              <li>{{ t("privacy.title3.list.list6") }}</li>
              <li>{{ t("privacy.title3.list.list7") }}</li>
              <li>{{ t("privacy.title3.list.list8") }}</li>
              <li>{{ t("privacy.title3.list.list9") }}</li>
            </ul>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title4.p1") }}</p>
            <span v-html="t('privacy.title4.p2')"></span>
            <ul>
              <li>{{ t("privacy.title4.list.list1") }}</li>
              <li>{{ t("privacy.title4.list.list2") }}</li>
              <li>{{ t("privacy.title4.list.list3") }}</li>
            </ul>
            <span v-html="t('privacy.title4.p3')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title5.p1") }}</p>
            <span v-html="t('privacy.title5.p2')"></span>
            <ul>
              <li
                v-html="
                  t('privacy.title5.list.list1', {
                    email: 'info@rightphone.com',
                  })
                "
              ></li>
              <li>{{ t("privacy.title5.list.list2") }}</li>
            </ul>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title6.p1") }}</p>
            <span
              v-html="t('privacy.title6.p2', { email: 'info@rightphone.com' })"
            ></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title7.p1") }}</p>
            <span v-html="t('privacy.title7.p2')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title8.p1") }}</p>
            <span v-html="t('privacy.title8.p2')"></span>
            <ul>
              <li v-html="t('privacy.title8.list.list1')"></li>
              <li>{{ t("privacy.title8.list.list2") }}</li>
              <li>{{ t("privacy.title8.list.list3") }}</li>
              <li>{{ t("privacy.title8.list.list4") }}</li>
              <li>{{ t("privacy.title8.list.list5") }}</li>
              <li>{{ t("privacy.title8.list.list6") }}</li>
            </ul>
            <span v-html="t('privacy.title8.p3')"></span>
            <ul>
              <li
                v-html="
                  t('privacy.title8.list2.list1', {
                    email: 'info@rightphone.com',
                  })
                "
              ></li>
              <li>{{ t("privacy.title8.list2.list2") }}</li>
            </ul>
            <span v-html="t('privacy.title8.p4')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title9.p1") }}</p>
            <span v-html="t('privacy.title9.p2')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title10.p1") }}</p>
            <span v-html="t('privacy.title10.p2')"></span>
          </div>

          <div class="content-option">
            <p class="font-weight">{{ t("privacy.title11.p1") }}</p>
            <span v-html="t('privacy.title11.p2')"></span>
          </div>
        </div>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t, locale } = useI18n();
    const contact = [
      {
        name: t('privacy.title2.p2'),
        description: t('privacy.title2.p3'),
      },
      {
        name: t('privacy.title2.p4'),
        description: 'RIGHTPHONE.COM',
      },
      {
        name: 'CIF: ',
        description: 'B88553532',
      },
      {
        name: t('privacy.title2.p8'),
        description: t('privacy.title2.p9'),
      },
      {
        name: 'E-mail: ',
        description: t('email', { email: 'info@rightphone.com' }),
      },
    ];
    const principles = [
      {
        title: t('privacy.title3.p1'),
        name: t('privacy.title3.p2'),
        description: [
          {
            name: t('privacy.title3.p4'),
            description: t('privacy.title3.p5'),
          },
          {
            name: t('privacy.title3.p6'),
            description: t('privacy.title3.p7'),
          },
          {
            name: t('privacy.title3.p8'),
            description: t('privacy.title3.p9'),
          },
          {
            name: t('privacy.title3.p10'),
            description: t('privacy.title3.p12'),
          },
          {
            name: t('privacy.title3.p13'),
            description: t('privacy.title3.p14'),
          },
          {
            name: t('privacy.title3.p15'),
            description: t('privacy.title3.p16'),
          },
          {
            name: t('privacy.title3.p17'),
            description: t('privacy.title3.p18'),
          },
          {
            name: t('privacy.title3.p19'),
            description: t('privacy.title3.p20'),
          },
          {
            name: t('privacy.title3.p21'),
            description: t('privacy.title3.p22'),
          },
          {
            name: t('privacy.title3.p23'),
            description: t('privacy.title3.p24'),
          },
        ],
      },
      {
        title: t('privacy.title4.p1'),
        name: t('privacy.title4.p2'),
        description: [
          {
            description: t('privacy.title4.p3'),
          },
          {
            description: t('privacy.title4.p4'),
          },
          {
            description: t('privacy.title4.p5'),
          },
        ],
      },
      {
        title: t('privacy.title5.p1'),
        name: t('privacy.title5.p2'),
        description: [
          {
            description: t('privacy.title5.p3'),
          },
          {
            description: t('privacy.title5.p4'),
          },
          {
            description: t('privacy.title5.p5'),
          },
          {
            description: t('privacy.title5.p6'),
          },
        ],
      },
    ];
    const data = [
      {
        title: t('privacy.title7.p1'),
        name: t('privacy.title7.p2'),
        description: [
          { description: t('privacy.title7.p3') },
          { description: t('privacy.title7.p4') },
          { description: t('privacy.title7.p5') },
          { description: t('privacy.title7.p6') },
          { description: t('privacy.title7.p7') },
          { description: t('privacy.title7.p8') },
          { des: t('privacy.title7.p9', { email: 'info@rightphone.com' }) },
        ],
      },
      {
        title: t('privacy.title8.p1'),
        name: t('privacy.title8.p2'),
      },
    ];

    return {
      t,
      locale,
      contact,
      principles,
      data,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include flex-center();
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 61px;
  .privacy-modal {
    p {
      margin: 0;
      font-size: 18px;
      line-height: 35px;
    }
    .title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #1c1c1c;
      line-height: 29px;
    }
    .content {
      .content-option {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 35px;
        .font-weight {
          font-weight: 600;
        }
        ul {
          display: inline-block;
          margin-bottom: 0px;
        }
        :deep(a) {
          color: #0000ee;
          &:active{
            color:#FF0000;
          }
        }
      }
    }
  }
}
</style>
