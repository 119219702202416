
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t, locale } = useI18n();
    const contact = [
      {
        name: t('privacy.title2.p2'),
        description: t('privacy.title2.p3'),
      },
      {
        name: t('privacy.title2.p4'),
        description: 'RIGHTPHONE.COM',
      },
      {
        name: 'CIF: ',
        description: 'B88553532',
      },
      {
        name: t('privacy.title2.p8'),
        description: t('privacy.title2.p9'),
      },
      {
        name: 'E-mail: ',
        description: t('email', { email: 'info@rightphone.com' }),
      },
    ];
    const principles = [
      {
        title: t('privacy.title3.p1'),
        name: t('privacy.title3.p2'),
        description: [
          {
            name: t('privacy.title3.p4'),
            description: t('privacy.title3.p5'),
          },
          {
            name: t('privacy.title3.p6'),
            description: t('privacy.title3.p7'),
          },
          {
            name: t('privacy.title3.p8'),
            description: t('privacy.title3.p9'),
          },
          {
            name: t('privacy.title3.p10'),
            description: t('privacy.title3.p12'),
          },
          {
            name: t('privacy.title3.p13'),
            description: t('privacy.title3.p14'),
          },
          {
            name: t('privacy.title3.p15'),
            description: t('privacy.title3.p16'),
          },
          {
            name: t('privacy.title3.p17'),
            description: t('privacy.title3.p18'),
          },
          {
            name: t('privacy.title3.p19'),
            description: t('privacy.title3.p20'),
          },
          {
            name: t('privacy.title3.p21'),
            description: t('privacy.title3.p22'),
          },
          {
            name: t('privacy.title3.p23'),
            description: t('privacy.title3.p24'),
          },
        ],
      },
      {
        title: t('privacy.title4.p1'),
        name: t('privacy.title4.p2'),
        description: [
          {
            description: t('privacy.title4.p3'),
          },
          {
            description: t('privacy.title4.p4'),
          },
          {
            description: t('privacy.title4.p5'),
          },
        ],
      },
      {
        title: t('privacy.title5.p1'),
        name: t('privacy.title5.p2'),
        description: [
          {
            description: t('privacy.title5.p3'),
          },
          {
            description: t('privacy.title5.p4'),
          },
          {
            description: t('privacy.title5.p5'),
          },
          {
            description: t('privacy.title5.p6'),
          },
        ],
      },
    ];
    const data = [
      {
        title: t('privacy.title7.p1'),
        name: t('privacy.title7.p2'),
        description: [
          { description: t('privacy.title7.p3') },
          { description: t('privacy.title7.p4') },
          { description: t('privacy.title7.p5') },
          { description: t('privacy.title7.p6') },
          { description: t('privacy.title7.p7') },
          { description: t('privacy.title7.p8') },
          { des: t('privacy.title7.p9', { email: 'info@rightphone.com' }) },
        ],
      },
      {
        title: t('privacy.title8.p1'),
        name: t('privacy.title8.p2'),
      },
    ];

    return {
      t,
      locale,
      contact,
      principles,
      data,
    };
  },
});
